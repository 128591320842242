


































import { Component, Prop, Vue } from 'vue-property-decorator';
import moment from 'moment';
import Promoter from '@/models/Promoter';

@Component
export default class PromotersTableComponent extends Vue {
  @Prop({ required: true })
  public promoters!: Promoter[];

  @Prop({ required: true })
  public onEditClicked!: (promoter: Promoter) => void;

  @Prop({ required: true })
  public onDeleteClicked!: (promoter: Promoter) => void;

  @Prop({ required: true })
  public onStatisticsClicked!: (promoter: Promoter) => void;

  @Prop({ default: '' })
  public searchValue!: string;

  @Prop({ required: true, default: false })
  private isLoading!: boolean;

  public tableHeaders = [{
      text: this.$t('PROMOTER.TABLE.FIRST_NAME'),
      value: 'firstName',
      sortable: true
  }, {
      text: this.$t('PROMOTER.TABLE.LAST_NAME'),
      value: 'lastName',
      sortable: false
  }, {
      text: this.$t('PROMOTER.TABLE.PHONE'),
      value: 'phone',
      sortable: false
  }, {
      text: this.$t('PROMOTER.TABLE.MAIL'),
      value: 'mail',
      sortable: false
  }, {
      text: this.$t('PROMOTER.TABLE.RIDES'),
      value: 'rideCount',
      sortable: false
  }, {
      text: this.$t('PROMOTER.TABLE.USERS'),
      value: 'passengerCount',
      sortable: false
  }, {
      text: this.$t('PROMOTER.TABLE.ACTIONS'),
      value: 'actions',
      align: 'end',
      sortable: false
  }];

  private statisticsClicked(item: Promoter): void {
      this.onStatisticsClicked(item);
  }

  private editPromoter(item: Promoter): void {
      this.onEditClicked(item);
  }

  private deletePromoter(item: Promoter) {
      this.onDeleteClicked(item);
  }

  private formatDate(value: string): string {
      if (!value) return '-';
      const date = moment(value, moment.ISO_8601, true);
      return date.format('DD.MM.yyyy HH:mm');
  }
}

